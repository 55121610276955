import React from 'react'

declare module '@mui/material/styles' {
  interface FiltoriaPrimary {
    bg: string
    lightest: string
    lighter: string
    light: string
    main: string
    medium: string
    dark: string
    darkest: string
  }

  interface FiltoriaSecondary {
    light: string
    main: string
    dark: string
    blue: string
  }

  interface FiltoriaGrey {
    lightest: string
    lighter: string
    light: string
    dark: string
    darker: string
    darkest: string
  }

  interface FiltoriaPalette {
    primary: FiltoriaPrimary
    secondary: FiltoriaSecondary
    grey: FiltoriaGrey
    form: FiltoriaFormPalette
  }

  interface FiltoriaPaletteOptions {
    primary: FiltoriaPrimary
    secondary: FiltoriaSecondary
    grey: FiltoriaGrey
  }

  interface FiltoriaFormPalette {
    select: React.CSSProperties
  }

  interface Palette {
    filtoria: FiltoriaPalette
  }

  interface PaletteOptions {
    filtoria?: FiltoriaPaletteOptions
  }
}

const filtoriaPrimary = {
  bg: '#F0F8F6',
  lightest: '#E5F5F2',
  lighter: '#C4EDE5',
  light: '#88D6C8',
  main: '#01A698',
  medium: '#0C867F',
  dark: '#026161',
  darkest: '#0A3D42',
}

const filtoriaSecondary = {
  light: '#FAECE2',
  main: '#FFB992',
  dark: '#FF7C44',
  blue: '#004858',
}

const filtoriaGrey = {
  lightest: '#F5F5F5',
  lighter: '#EBEBED',
  light: '#CFCFD3',
  dark: '#8B8B8B',
  darker: '#575757',
  darkest: '#1A1A1B',
}

export default {
  filtoria: {
    primary: filtoriaPrimary,
    secondary: filtoriaSecondary,
    grey: filtoriaGrey,
    form: {
      select: {
        borderRadius: '1.875rem',
        padding: '0 1rem',
      },
    },
  },
}
