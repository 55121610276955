import { GraphQLProviderProps } from '@graphcommerce/graphql'
import type { PluginProps } from '@graphcommerce/next-config'
import { ApolloLink, onError } from '@graphcommerce/graphql/apollo'
import { CurrentCartIdDocument } from '@graphcommerce/magento-cart/hooks/CurrentCartId.gql'
import { cookie } from '@graphcommerce/next-ui'
import { CART_ID_COOKIE } from '@graphcommerce/magento-cart/hooks/useAssignCurrentCartId'

export const component = 'GraphQLProvider'
export const exported = '@graphcommerce/graphql'

export const onCartError = onError(({ graphQLErrors, operation }) => {
  const { cache } = operation.getContext()
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions?.category === 'graphql-no-cart') {
        console.log('graphql-no-cart')
        cache.writeQuery({
          query: CurrentCartIdDocument,
          data: { currentCartId: { __typename: 'CurrentCartId', id: null } },
          broadcast: true,
        })
        cookie(CART_ID_COOKIE, null)
        break
      }
    }
  }
})

export const cartLink = ApolloLink.from([onCartError])

/** Not really required anymore, you can use customerTokenLink directly */

function MagentoCustomCartGraphqlProvider(props: PluginProps<GraphQLProviderProps>) {
  const { Prev, links = [], policies = [], migrations = [], ...rest } = props
  return <Prev {...rest} links={[...links, cartLink]} policies={policies} migrations={migrations} />
}

export const Plugin = MagentoCustomCartGraphqlProvider
