import React, { useEffect, useState } from 'react'
import { PluginProps } from '@graphcommerce/next-config'
import Cookie from 'js-cookie'
import Script from 'next/script'
import { useStorefrontConfig } from '@graphcommerce/next-ui'
import {
  COOKIE_CONSENT_NAME,
  MARKETING_COOKIE_KEY_WORD,
} from '../../common/constants/cookie.constant'

export const component = 'GoogleTagManagerScript'
export const exported = '@graphcommerce/googletagmanager/components/GoogleTagManagerScript'

function GoogleTagManagerScriptPlugin(props: PluginProps) {
  const [consent, setConsent] = useState(false)
  const { Prev, ...rest } = props

  const id =
    useStorefrontConfig().googleTagmanagerId ?? import.meta.graphCommerce.googleTagmanagerId

  const generateConsentScript = (consentSettings) => {
    const fullConsent = {
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
      functionality_storage: 'granted',
      personalization_storage: 'granted',
      security_storage: 'granted',
    }

    if (consentSettings) {
      const { functional, analytical, marketing } = consentSettings

      fullConsent['ad_storage'] = marketing ? 'granted' : 'denied'
      fullConsent['analytics_storage'] = analytical ? 'granted' : 'denied'
      fullConsent['personalization_storage'] = functional ? 'granted' : 'denied'
    }

    const fullConsentString = JSON.stringify(fullConsent)
    return `
        gtag('consent', 'default', ${fullConsentString});
      `
  }

  useEffect(() => {
    const consentCookie = Cookie.get(COOKIE_CONSENT_NAME)
    if (consentCookie) {
      const consentKeys = JSON.parse(consentCookie ?? '{}')
      setConsent(consentKeys)
    }
  }, [])

  if (!consent) return <React.Fragment />
  //return <Prev {...rest} />
  return (
    <React.Fragment>
      <Prev {...rest} />
      {/* Ensure Google Tag Manager Script is loaded */}
      <Script id='gtag' strategy='afterInteractive'>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          // gtag('js', new Date());
          // gtag('config', '${id}', { 'debug_mode':true });
          ${generateConsentScript(consent)}
      `}</Script>
    </React.Fragment>
  )
}

export const Plugin = GoogleTagManagerScriptPlugin
