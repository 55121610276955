/// <reference types="@graphcommerce/next-ui/types" />

import {
  responsiveVal,
  breakpointVal,
  MuiButtonPill,
  MuiButtonResponsive,
  themeBaseDefaults,
  MuiSnackbar,
  MuiFabSizes,
  MuiSlider,
  MuiChip,
  MuiButtonInline,
  NextLink,
  iconPerson,
  iconShoppingBag,
  iconSearch,
  iconMenu,
  iconClose,
  iconChevronRight,
  iconChevronLeft,
} from '@graphcommerce/next-ui'
import { createTheme, Theme, alpha, LinkProps } from '@mui/material'
import { Components, PaletteOptions } from '@mui/material/styles'
import filtoriaTheme from './FiltoriaTheme'
import UserIconSvg from './Common/Icons/User/user-icon.svg'
import CartIconSvg from './Common/Icons/Cart/cart-icon.svg'
import SearchIconSvg from './Common/Icons/Search/search-icon.svg'
import MenuIconSvg from './Common/Icons/Menu/menu-icon.svg'
import ChevronRightIcon from './Common/Icons/ChevronRight/chevron-right-icon.svg'
import ChevronLeftIcon from './Common/Icons/ArrowLeft/arrow-left-icon.svg'
import CloseIcon from './Common/Icons/Close/close-icon.svg'
import { MuiButtonColorLight, MuiButtonColorNone } from './themeButton'
import { DM_Sans } from 'next/font/google'

const dmSansFonts = DM_Sans({
  variable: '--font-dmsans',
  weight: ['400', '500', '700'],
  subsets: ['latin'],
})

const lightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#026161',
    contrastText: '#ffffff',
    dark: '#026161',
  },
  secondary: {
    main: '#006bff',
    light: '#d1e4ff',
    contrastText: '#ffffff',
  },
  background: {
    default: '#ffffff',
    paper: '#ffffff',
    image: '#ffffff',
  },
  divider: '#D8D6D4',
  success: {
    main: '#01d26a',
  },
  action: {
    hoverOpacity: 0.12,
  },
  text: {
    primary: '#0F0F10',
    secondary: '#026161',
    disabled: '#03031735',
  },
}

const darkPalette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#62C7B0',
    contrastText: '#ffffff',
    dark: '#62C7B0',
  },
  secondary: {
    main: '#62C7B0',
    light: '#62C7B0',
    contrastText: '#ffffff',
  },
  background: {
    default: '#001727',
    paper: '#15293B',
    image: '#ffffff',
  },
  divider: '#ffffff30',
  success: {
    main: '#01D26A',
  },
  action: {
    hoverOpacity: 0.16,
  },
  text: {
    primary: '#ffffff',
    secondary: '#ffffff80',
    disabled: '#ffffff30',
  },
}

const fontSize = (from: number, to: number) =>
  breakpointVal('fontSize', from, to, themeBaseDefaults.breakpoints.values)

declare module '@mui/material/styles' {
  interface Theme {
    spacingRem: (factor: number) => string
  }

  // Allow configuration using `createTheme`
  interface ThemeOptions {
    spacingRem: (factor: number) => string
  }
}

// Create a theme instance.
const createThemeWithPalette = (palette: PaletteOptions) =>
  createTheme({
    palette: { ...palette, ...filtoriaTheme },
    ...themeBaseDefaults,
    shape: { borderRadius: 3 },
    spacingRem: (factor) => `${0.25 * factor}rem`,
    typography: {
      fontFamily:
        // 'Raleway,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
        dmSansFonts.style.fontFamily,
      // @see docs typography.md
      h1: {
        ...fontSize(34, 34),
        fontWeight: 700,
        fontVariationSettings: "'wght' 660",
        lineHeight: 1.22,
      },
      h2: {
        // ...fontSize(22, 22),
        fontSize: '1.325rem',
        fontWeight: 700,
        fontVariationSettings: "'wght' 630",
        lineHeight: 1.35,
      },
      h3: {
        // ...fontSize(18, 18),
        fontSize: '1.125rem',
        fontWeight: 700,
        fontVariationSettings: "'wght' 660",
        lineHeight: '150%',
        letterSpacing: '0.00625rem',
      },
      h4: {
        ...fontSize(14, 14),
        fontWeight: 550,
        fontVariationSettings: "'wght' 550",
        lineHeight: 1.55,
      },
      h5: {
        ...fontSize(13, 13),
        fontWeight: 650,
        fontVariationSettings: "'wght' 650",
        lineHeight: 1.55,
      },
      h6: {
        ...fontSize(13, 13),
        fontWeight: 550,
        fontVariationSettings: "'wght' 510",
        lineHeight: 1.8,
      },
      subtitle1: {
        ...fontSize(16, 16),
        fontWeight: 450,
        fontVariationSettings: "'wght' 460",
        lineHeight: 1.7,
      },
      fontWeightBold: 600,
      body1: {
        // ...fontSize(16, 16),
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '150%',
      },
      subtitle2: {
        ...fontSize(14, 14),
        fontWeight: 500,
        fontVariationSettings: "'wght' 520",
        lineHeight: 1.7,
      },
      body2: {
        // ...fontSize(14, 14),
        fontSize: '0.875rem',
        lineHeight: '150%',
      },
      caption: {
        // https://web.dev/font-size/#how-the-lighthouse-font-size-audit-fails
        ...fontSize(12, 13),
      },
      button: {
        ...fontSize(14, 14),
      },
      overline: {
        // https://web.dev/font-size/#how-the-lighthouse-font-size-audit-fails
        ...fontSize(12, 12),
        fontWeight: 500,
        letterSpacing: 1,
        lineHeight: 1.2,
        textTransform: 'uppercase',
      },
    },
    spacings: {
      xxs: responsiveVal(10, 16),
      xs: responsiveVal(12, 20),
      sm: responsiveVal(14, 30),
      md: responsiveVal(16, 50),
      lg: responsiveVal(24, 80),
      xl: responsiveVal(40, 100),
      xxl: responsiveVal(80, 160),
    },
    page: {
      horizontal: responsiveVal(10, 30),
      vertical: responsiveVal(10, 30),
    },
    appShell: {
      headerHeightSm: '52px',
      headerHeightMd: '100px',
      appBarHeightMd: '66px',
      appBarInnerHeightMd: '46px',
    },
  })

// todo: move most of the styles to the graphcommerce library while still allowing for extensibility.
const createOverrides = (theme: Theme): Components => ({
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        overflowY: 'scroll',
      },
      '::selection': { background: alpha(theme.palette.primary.main, 0.6) },
      '::-moz-selection': { background: alpha(theme.palette.primary.main, 0.6) },
      '#__next': {
        position: 'relative',
      },
      'picture img': {
        // filter: 'brightness(1.03)',
        // willChange: 'filter',
      },
    },
  },

  // https://mui.com/material-ui/guides/routing/#global-theme-link
  // https://www.graphcommerce.org/docs/framework/links
  MuiLink: {
    defaultProps: { component: NextLink } as LinkProps,
    styleOverrides: {
      root: {
        color: theme.palette.filtoria.grey.darkest,
      },
    },
  },
  MuiButtonBase: { defaultProps: { LinkComponent: NextLink } },

  MuiContainer: {
    variants: [
      {
        props: { disableGutters: false },
        style: {
          // paddingLeft: theme.page.horizontal,
          // paddingRight: theme.page.horizontal,
          // [theme.breakpoints.up('sm')]: {
          //   paddingLeft: theme.page.horizontal,
          //   paddingRight: theme.page.horizontal,
          // },
          paddingLeft: '1rem',
          paddingRight: '1rem',
        },
      },
    ],
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: '16px', // https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom/
      },
    },
  },

  MuiButton: {
    defaultProps: { color: 'inherit' },
    styleOverrides: {
      root: {
        '.rounded-button': {
          borderRadius: '1.875rem',
        },
      },
    },
    variants: [
      ...MuiButtonResponsive,
      ...MuiButtonPill,
      ...MuiButtonInline,
      ...MuiButtonColorLight,
      ...MuiButtonColorNone,
      {
        props: { size: 'large' },
        style: { paddingY: theme.spacingRem(2) },
      },
      {
        props: { variant: 'contained', color: 'inherit' },
        style: { backgroundColor: theme.palette.background.paper },
      },
      {
        props: { variant: 'outlined' },
        style: {
          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 2,
            theme.shape.borderRadius * 3,
            theme.breakpoints.values,
          ),
        },
      },
      {
        props: { variant: 'text' },
        style: { borderRadius: '99em' },
      },
      {
        props: { variant: 'inline' },
        style: { borderRadius: '99em' },
      },
      {
        props: { color: 'primary' },
        style: {
          backgroundColor: theme.palette.filtoria.primary.dark,
          color: theme.palette.filtoria.grey.lightest,
          fontWeight: 500,
          fontSize: '1rem !important',
          lineHeight: '200%',
          '&:hover': {
            backgroundColor: theme.palette.filtoria.primary.medium,
            color: theme.palette.filtoria.grey.lightest,
            outlineColor: theme.palette.filtoria.primary.medium,
          },
          '&:not(.Mui-disabled)': {
            boxShadow: 'none',
          },
        },
      },
      {
        props: { color: 'secondary' },
        style: {
          color: theme.palette.filtoria.grey.darkest,
          outline: 'solid 1px',
          outlineColor: theme.palette.filtoria.grey.darkest,
          fontWeight: 500,
          fontSize: '1rem !important',
          lineHeight: '200%',
          boxSizing: 'border-box',
          '&:hover': {
            backgroundColor: theme.palette.filtoria.primary.dark,
            outlineColor: theme.palette.filtoria.primary.dark,
            color: theme.palette.filtoria.grey.lightest,
            '& .MuiButton-endIcon': { color: theme.palette.filtoria.grey.lightest },
          },
          '& .MuiButton-endIcon': { color: theme.palette.filtoria.primary.dark },
          '&:not(.Mui-disabled)': {
            boxShadow: 'none',
          },
        },
      },
      {
        props: { color: 'primary', variant: 'pill' },
        style: {
          backgroundColor: theme.palette.filtoria.primary.dark,
          color: theme.palette.filtoria.grey.lightest,
        },
      },
      {
        props: { color: 'secondary', variant: 'pill' },
        style: {
          color: theme.palette.filtoria.grey.darkest,
          outline: 'solid 1px',
          outlineColor: theme.palette.filtoria.grey.darkest,
        },
      },
      {
        props: { disabled: true },
        style: {
          // color: theme.palette.filtoria.grey.lightest,
          color: '#ffffff !important',
          outline: 'unset',
          backgroundColor: theme.palette.filtoria.grey.light,
          '& .MuiButton-endIcon': { color: '#ffffff !important' },
        },
      },
    ],
  },

  MuiFab: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
          backgroundColor: theme.palette.background.paper,
        },
        color: theme.palette.text.primary,
      },
      colorInherit: {
        backgroundColor: 'inherit',
        '&:hover, &:focus': {
          backgroundColor: 'inherit',
        },
        boxShadow: 'none',
      },
      primary: {
        color: theme.palette.text.primary,
      },
      secondary: {
        color: theme.palette.text.primary,
      },
      extended: {
        fontWeight: 400,
        textTransform: 'none',
      },
    },

    variants: [...MuiFabSizes],
  },

  MuiTextField: {
    defaultProps: { color: 'secondary' },
    styleOverrides: {
      root: {
        '& .MuiFormLabel-root': {
          color: theme.palette.filtoria.grey.darkest,
        },
        '& label.Mui-focused': {
          color: theme.palette.filtoria.grey.darkest,
        },
        '& .MuiOutlinedInput-root, .MuiOutlinedInput-input': {
          borderRadius: '0.75rem',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.filtoria.grey.darkest,
          },
          '& fieldset': {
            borderColor: theme.palette.filtoria.grey.darkest,
          },
          '&:hover fieldset': {
            borderColor: theme.palette.filtoria.grey.darkest,
          },
          '&.Mui-focused fieldset': {
            borderColor: theme.palette.filtoria.grey.darkest,
          },
        },
        '&.rounded-input': {
          height: '48px !important',
          '& .MuiOutlinedInput-root, .MuiOutlinedInput-input': {
            borderRadius: '12px',
          },
          '& .MuiInputBase-input': {
            padding: '12.5px 14px',
          },
          '& .MuiInputLabel-root': {
            top: '-4px',
          },
        },
      },
    },
  },

  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },

  MuiChip: {
    variants: [...MuiChip],
  },

  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        color: theme.palette.text.disabled,
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
      },
      colorSecondary: {
        color: theme.palette.text.disabled,
        '&.Mui-checked': {
          color: theme.palette.secondary.main,
        },
      },
    },

    variants: [
      {
        props: { size: 'medium' },
        style: {
          padding: 7,
        },
      },
    ],
  },

  MuiSwitch: {
    styleOverrides: {
      root: {
        padding: 0,
      },
      thumb: {
        boxShadow: theme.shadows[6],
        backgroundColor: theme.palette.primary.contrastText,
      },
      track: {
        opacity: 1,
        borderRadius: theme.spacing(1.5),
        backgroundColor: '#D8D6D4',
        '&.Mui-checked': {
          backgroundColor: theme.palette.filtoria.primary.main,
        },
      },
    },
  },

  MuiSnackbar: { variants: [...MuiSnackbar] },

  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: theme.palette.text.disabled,
      },
    },
  },

  MuiSlider: { variants: [...MuiSlider] },

  MuiCircularProgress: {
    defaultProps: {
      thickness: 2,
    },
  },

  IconSvg: {
    overrides: [
      [iconPerson, UserIconSvg],
      [iconShoppingBag, CartIconSvg],
      [iconSearch, SearchIconSvg],
      [iconMenu, MenuIconSvg],
      [iconClose, CloseIcon],
      [iconChevronRight, ChevronRightIcon],
      [iconChevronLeft, ChevronLeftIcon],
    ],
  },
})

export const lightTheme = createThemeWithPalette(lightPalette)
lightTheme.components = createOverrides(lightTheme)

export const darkTheme = createThemeWithPalette(darkPalette)
darkTheme.components = createOverrides(darkTheme)
