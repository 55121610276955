import { ComponentsVariants } from '@mui/material'

declare module '@mui/material/Button/Button' {
  // interface ButtonPropsVariantOverrides {
  //   dark: true
  // }

  interface ButtonPropsColorOverrides {
    light: true
    none: true
  }
}

type ButtonVariants = NonNullable<ComponentsVariants['MuiButton']>

export const MuiButtonColorLight: ButtonVariants = [
  {
    props: { color: 'light' },
    style: ({ theme }) => ({
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.filtoria.grey.darkest,
      fontWeight: 500,
      fontSize: '16px !important',
      lineHeight: '20px',
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: theme.palette.filtoria.primary.bg,
        color: theme.palette.filtoria.grey.darkest,
      },
    }),
  },
]

export const MuiButtonColorNone: ButtonVariants = [
  {
    props: { color: 'none' },
    style: () => ({
      backgroundColor: 'transparent',
    }),
  },
]
