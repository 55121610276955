import { FramerNextPages } from '@graphcommerce/framer-next-pages'
import { GraphQLProvider } from '@graphcommerce/graphql'
import { GlobalHead } from '@graphcommerce/magento-store'
import {
  CssAndFramerMotionProvider,
  DarkLightModeThemeProvider,
  PageLoadIndicator,
} from '@graphcommerce/next-ui'
import { CssBaseline } from '@mui/material'
import { AppProps } from 'next/app'
import { lightTheme } from '../components/theme'
import { I18nProvider } from '../lib/i18n/I18nProvider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../components/Catalog/styles.scss'
import '../components/Common/global.scss'
import '../components/Home/styles.scss'
import '../components/Account/scss/styles.scss'
import '../components/Account/scss/account-order.scss'
import '../components/Account/scss/account-personal.scss'
import '../components/Catalog/Product/styles.scss'
import '../components/Blog/styles.scss'
import '../components/OrderCard/styles.scss'
import React from 'react'

export default function ThemedApp(props: AppProps) {
  const { router } = props
  const { locale = 'nb' } = router
  let pageBehindOverlay = ''

  if (['/add-filter', '/account'].some((item: string) => router.asPath.includes(item))) {
    pageBehindOverlay = 'account'
  }
  if (
    router.asPath.includes('/account/login') ||
    router.asPath.includes('/account/register') ||
    router.asPath.includes('/forgot-password')
  ) {
    pageBehindOverlay = ''
  }

  return (
    <CssAndFramerMotionProvider>
      <I18nProvider key={locale} locale={locale}>
        <GraphQLProvider {...props}>
          <DarkLightModeThemeProvider light={lightTheme} dark={lightTheme}>
            <ToastContainer />
            <GlobalHead />
            <CssBaseline />
            <PageLoadIndicator />
            <FramerNextPages {...props} fallbackRoute={`/${pageBehindOverlay}`} />
          </DarkLightModeThemeProvider>
        </GraphQLProvider>
      </I18nProvider>
    </CssAndFramerMotionProvider>
  )
}
