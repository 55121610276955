export const COOKIE_CONSENT_NAME = 'cookies-consent'
export const COOKIE_CART_NAME = 'cart'
export const FUNCTIONAL_COOKIE_KEY_WORD = 'functional'
export const ANALYTICAL_COOKIE_KEY_WORD = 'analytical'
export const MARKETING_COOKIE_KEY_WORD = 'marketing'

export const ANALYTICAL_COOKIES = ['_ga_*', '_ga']
export const MARKETING_COOKIES = [
  '_gcl_au',
  '_fbp',
  'test_cookie',
  'lastExternalReferrerTime',
  'lastExternalReferrer',
]
export const FUNCTIONAL_COOKIES = ['apollo-cache-version', 'apollo-cache-persist']
